import React, { useState } from 'react';
import axios from 'axios';
import './BuyerForm.css';
import { sellerTemplate, buyerTemplate, otherTemplate } from '../../whatsapp_temp';

const BuyerForm = ({ onAddBuyer }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailID, setEmailID] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [notes, setNotes] = useState('');
    const todayDate = new Date().toISOString().split('T')[0];
    const [lastMailShared, setLastMailShared] = useState(todayDate);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State for error message
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [customMessage, setCustomMessage] = useState({});
    const [customMessageVisible, setCustomMessageVisible] = useState({});
    const [templates] = useState(['Seller', 'Buyer', 'Other']); // Added template options

    

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Create the buyer data object
        const buyerData = {
            firstName,
            lastName,
            companyName,
            phoneNumber,
            emailID,
            city,
            state,
            notes,
            lastMailShared,
        };

        try {
            // console.log('Sending buyer data:', buyerData);
            const response = await axios.post('http://localhost:5001/Buyers', buyerData);
            // console.log('Buyer added:', response.data);
            onAddBuyer(response.data); // Notify parent about the new Buyer

            // Display success message
            setSuccessMessage('Buyer added successfully!');

            // Use the appropriate WhatsApp template
            let message = '';
            if (selectedTemplate === 'Seller') {
                message = sellerTemplate; //.replace('${firstName}', firstName).replace('${lastName}', lastName).replace('${companyName}', companyName);
            } else if (selectedTemplate === 'Buyer') {
                message = buyerTemplate; //.replace('${firstName}', firstName);
            } else if (selectedTemplate === 'Other') {
                message = otherTemplate;
            }

           // Construct WhatsApp URL
           const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

           // Ensure the window.open call is made directly as part of the user action (onSubmit)
           setTimeout(() => {
               const whatsappWindow = window.open(whatsappURL, '_blank');
               if (!whatsappWindow) {
                   alert('Please allow popups for this site to open WhatsApp.');
               }
           }, 500); // Timeout to ensure the message is constructed first

            setErrorMessage(''); // Clear any previous error message

            // Clear form fields after submission
            setFirstName('');
            setLastName('');
            setCompanyName('');
            setPhoneNumber('');
            setEmailID('');
            setCity('');
            setState('');
            setNotes('');
            setLastMailShared(todayDate);
        } catch (error) {
            // Update error handling to display a user-friendly message
            console.error('Error adding buyer:', error.response ? error.response.data : error.message);
            setErrorMessage(error.response ? error.response.data.error : 'Failed to add buyer. Please try again.');
            setSuccessMessage(''); // Clear success message on error
        }
    };

    const handleClear = () => {
        // Clear form fields
        setFirstName('');
        setLastName('');
        setCompanyName('');
        setPhoneNumber('');
        setEmailID('');
        setCity('');
        setState('');
        setNotes('');
        setLastMailShared(todayDate);
        setSuccessMessage(''); // Clear success message
        setErrorMessage(''); // Clear error message
    };

    const handleTemplateSelect = (selectedTemplateIndex) => {
        setSelectedTemplate(templates[selectedTemplateIndex]);
        const isCustomMessage = selectedTemplateIndex === '2'; // 'Other' template index
        setCustomMessageVisible({ ...customMessageVisible, [selectedTemplateIndex]: isCustomMessage });
    };

    const states = [
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal',
        'Delhi',
        'Chandigarh',
        'Puducherry',
        'Ladakh',
        'Jammu and Kashmir',
        'Lakshadweep',
    ];

    return (
        <div className='buyerForm'>
            <form className='buyFrm' onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <input
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="First Name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Last Name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="companyName">Company Name</label>
                        <input
                            type="text"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            placeholder="Company Name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input
                            type="text"
                            value={phoneNumber.startsWith("91") ? phoneNumber : "91" + phoneNumber}
                            onChange={(e) => {
                                const input = e.target.value;
                                // Prevent removal of "91" and keep numbers only
                                if (input.startsWith("91") && /^\d*$/.test(input)) {
                                    setPhoneNumber(input);
                                } else if (/^\d*$/.test(input.slice(2))) {
                                    setPhoneNumber("91" + input.slice(2));
                                }
                            }}
                            placeholder="Phone Number"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="emailID">Email ID</label>
                        <input
                            type="email"
                            value={emailID}
                            onChange={(e) => setEmailID(e.target.value)}
                            placeholder="Email ID"
                            
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            placeholder="City"
                            
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="state">State</label>
                        <select
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            required
                        >
                            <option value="">Select a state</option>
                            {states.map((state, index) => (
                                <option key={index} value={state}>{state}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="notes">Notes</label>
                        <input
                            type="text"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            placeholder="Notes"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastMailShared">Mail Shared on</label>
                        <input
                            type="date"
                            value={lastMailShared}
                            onChange={(e) => setLastMailShared(e.target.value)}
                            required
                        />
                    </div>
                    <div className="template-options">
                        <p>Select a template:</p>
                        <select
                            onChange={(e) => handleTemplateSelect(e.target.value)}
                        >
                            <option value="">Select a Whatsapp template</option>
                            {templates.map((template, index) => (
                                <option key={index} value={index}>
                                    {template}
                                </option>
                            ))}
                        </select>

                        {customMessageVisible[selectedTemplate] && (
                            <>
                                <textarea
                                    placeholder="Enter your custom message"
                                    value={customMessage[selectedTemplate] || ""}
                                    onChange={(e) =>
                                        setCustomMessage((prev) => ({
                                            ...prev,
                                            [selectedTemplate]: e.target.value,
                                        }))
                                    }
                                ></textarea>
                            </>
                        )}
                    </div>
                </div>
                <div className="submit-btn">
                    <button type="submit">Add Buyer</button>
                    <button type="button" onClick={handleClear}>Clear</button>
                </div>
                {successMessage && <div className="success-message">{successMessage}</div>}
                {errorMessage && <div className="error-message">{errorMessage}</div>} {/* Display error message */}
            </form>
        </div>
    );
};

export default BuyerForm;
