import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './SellerList.css'; // Import the stylesheet
import { sellerTemplate, otherTemplate } from '../../whatsapp_temp';

    const SellerList = () => {
    const [sellers, setSellers] = useState([]);
    const [editMode, setEditMode] = useState(false);  // For toggling edit mode
    const [editSeller, setEditSeller] = useState({});  // Seller being edited
    const [showTemplates, setShowTemplates] = useState({}); // To manage template visibility
    const [customMessage, setCustomMessage] = useState(""); // For handling custom message input
    const [customMessageVisible, setCustomMessageVisible] = useState({}); // To track visibility of the textarea
    const [loading, setLoading] = useState(true);
    const [selectedSellers, setSelectedSellers] = useState({}); // New state to track selected checkboxes
    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [filteredSellers, setFilteredSellers] = useState([]); // New state for filtered buyers
    // const [selectedTemplate, setSelectedTemplate] = useState(''); // To store the selected template

    // Fetch the sellers from the backend
    useEffect(() => {
        const fetchSellers = async () => {
            try {
                const response = await axios.get('http://localhost:5001/sellers');
                // Check for sellerID and sort sellers with valid IDs only
                const sortedSellers = response.data.sort((a, b) => {
                    if (a.sellerID && b.sellerID) {
                        return a.sellerID.localeCompare(b.sellerID);
                    }
                    return 0; // No sorting if one of the sellerIDs is missing
                });
        
                setSellers(sortedSellers);
            } catch (error) {
                console.error('Error fetching sellers:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchSellers();
    }, []);

    // Automatically filter buyers as searchQuery changes
    useEffect(() => {
        if (searchQuery.trim() === "") {
            setFilteredSellers(sellers); // Reset to the full list if the query is empty
        } else {
            const filtered = sellers.filter((seller) =>
                seller.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                seller.lastName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                seller.phoneNumber?.includes(searchQuery) ||
                seller.emailID?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                seller.state?.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredSellers(filtered);
        }
    }, [searchQuery, sellers]);

    if (loading) {
        return <div>Loading Seller List...</div>;
    }

    // Handle "Select All" checkbox change
    const handleSelectAllChange = (e) => {
        const { checked } = e.target;
        const newSelectedSellers = {};
        sellers.forEach((seller) => {
            newSelectedSellers[seller.id] = checked;
        });
        setSelectedSellers(newSelectedSellers);
    };

    // Handle individual checkbox change
    const handleCheckboxChange = (sellerId) => {
        setSelectedSellers((prev) => ({
            ...prev,
            [sellerId]: !prev[sellerId]
        }));
    };

    // Check if all sellers are selected
    const areAllSelected = sellers.length > 0 && sellers.every(seller => selectedSellers[seller.id]);

    // Function to handle sending WhatsApp message
    const sendWhatsapp = (seller, template) => {
        // Create the WhatsApp message by replacing placeholders with actual buyer details
        const whatsappMessage = template
            .replace("{firstName}", seller.firstName)
            .replace("{lastName}", seller.lastName)
            .replace("{companyName}", seller.companyName);

        // WhatsApp URL encoding the message and phone number
        const whatsappUrl = `https://wa.me/${seller.phoneNumber}?text=${encodeURIComponent(whatsappMessage)}`;

        // Open WhatsApp Web with the message
        window.open(whatsappUrl, '_blank');

        // Create updated Seller object with the current date for lastWhatsAppShared
        const updatedSeller = {
            ...seller ,
            lastWhatsAppShared: new Date().toISOString() // Set current date as lastWhatsAppShared
        };

        // Update the seller in the local state
        setSellers(sellers.map(s => s.id === updatedSeller.id ? updatedSeller : s));

        // Optionally, you can make an API call to update this in the backend as well
        updateSeller(updatedSeller);
    };

    // Predefined templates
    const templates = [
        sellerTemplate, otherTemplate
    ];

    // Function to handle template selection and sending the message
    const handleTemplateSelect = (seller, templateIndex) => {
        if (templateIndex === 1) {
            // Show textarea for custom message if "Other" is selected
            setCustomMessageVisible((prev) => ({
                ...prev,
                [seller.id]: true // Only show for the specific seller
            }));
        } else {
            // Hide the textarea if any predefined template is selected
            setCustomMessageVisible((prev) => ({
                ...prev,
                [seller.id]: false
            }));
            const template = templates[templateIndex];
            sendWhatsapp(seller, template);
        }
    };    

    // Function to toggle template options
    const toggleTemplates = (sellerId) => {
        setShowTemplates((prev) => ({
            ...prev,
            [sellerId]: !prev[sellerId] // Toggle the visibility for the specific seller
        }));
    };

    // Function to handle seller update (PUT request)
    const updateSeller = async (sellerData) => {
        try {
            const response = await axios.put(`http://localhost:5001/sellers/${sellerData.id}`, sellerData, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }
            });
            console.log('Seller updated:', response.data);
            // Update the seller in the local list
            setSellers(sellers.map(s => s.id === response.data.id ? response.data : s));
            setEditMode(false); // Exit edit mode
        } catch (error) {
            console.error('Error updating seller:', error);
        }
    };

    // Bulk Send WhatsApp to selected sellers
    const bulkSendWhatsapp = (templateIndex) => {
        const template = templates[templateIndex];
        sellers.forEach(seller => {
            if (selectedSellers[seller.id]) {
                sendWhatsapp(seller, template);
            }
        });
    };

    // Bulk Send Email to selected sellers
    const bulkSendEmail = async () => {
        const selected = sellers.filter(seller => selectedSellers[seller.id]);
        for (let seller of selected) {
            await sendEmail(seller);
        }
    };

    // Updated function to handle sending email
    const sendEmail = async (seller) => {
        try {
            const response = await axios.post(`http://localhost:5001/sellers/${seller.id}/send-email`, {
                emailID: seller.emailID,
                subject: "Vidyutcloud - E-commerce Website For Electrical Products",  // New subject
                sellerInfo: {  // Pass relevant seller info to the backend
                    firstName: seller.firstName,
                    lastName: seller.lastName,
                    companyName: seller.companyName,
                    phoneNumber: seller.phoneNumber,
                    emailID: seller.emailID,
                    website: seller.website,
                    city: seller.city,
                    state: seller.state
                }
            });
            console.log('Email sent:', response.data);
            alert(`Email sent to ${seller.emailID}`);

            // Update the "lastMailShared" date after successful email
            const updatedSeller = {
                ...seller,
                lastMailShared: new Date().toISOString() // Set current date as lastMailShared
            };

            // Update the seller in the local state
            setSellers(sellers.map(s => s.id === updatedSeller.id ? updatedSeller : s));

            // Optionally, you can make an API call to update this in the backend as well
            await updateSeller(updatedSeller);
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error sending email');
        }
    };

    // Function to handle delete (DELETE request)
    const deleteSeller = async (sellerId) => {
        if (window.confirm("Are you sure you want to delete this seller?")) {
            try {
                const response = await axios.delete(`http://localhost:5001/sellers/${sellerId}`);
                console.log('Seller deleted:', response.data);
                // Remove the deleted seller from the local list
                setSellers(sellers.filter(seller => seller.id !== sellerId));
            } catch (error) {
                console.error('Error deleting seller:', error);
                alert('Error deleting seller');
            }
        }
    };

    // Function to handle edit click
    const handleEditClick = (seller) => {
        setEditSeller(seller);
        setEditMode(true); // Enable edit mode
    };

    // Function to handle form input changes
    const handleChange = (e) => {
        setEditSeller({
            ...editSeller,
            [e.target.name]: e.target.value
        });
    };

    const states = [
        'Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chhattisgarh','Goa','Gujarat','Haryana','Himachal Pradesh','Jharkhand','Karnataka','Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland','Odisha','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal','Delhi','Chandigarh','Puducherry','Ladakh','Jammu and Kashmir','Lakshadweep'];

    // Check if any sellers are selected
    const anySellersSelected = Object.values(selectedSellers).some(selected => selected);

    return (
        <div>
            <h2>Seller List</h2>
            <div className="search-container">
                    {/* Search Input */}
                    <input
                        type="text"
                        placeholder="Search by First Name, Last Name, Contact Number, Email ID, or State"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"/>
                </div>
            {/* Bulk Actions */}
            {anySellersSelected && (
                <div className="bulk-actions">
                    <button onClick={() => bulkSendEmail()}>Send Bulk Email</button>
                    <button onClick={() => bulkSendWhatsapp(0)}>Seller</button>
                    <button onClick={() => bulkSendWhatsapp(1)}>Custom Message</button>
                </div>
            )}
            {editMode ? (
                <div>
                    <h3>Edit Seller</h3>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        updateSeller(editSeller); // Submit the updated seller
                    }}>
                        <div class="form-row">
                        <label htmlFor="firstName">First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                value={editSeller.firstName}
                                onChange={handleChange}
                                placeholder="First Name"
                                required
                            />
                            <label htmlFor="lastName">Last Name</label>
                        <input
                            type="text"
                            name="lastName"
                            value={editSeller.lastName}
                            onChange={handleChange}
                            placeholder="Last Name"
                            required
                        />
                        <label htmlFor="companyName">Company Name</label>
                        <input
                            type="text"
                            name="companyName"
                            value={editSeller.companyName}
                            onChange={handleChange}
                            placeholder="Company Name"
                            required
                        />
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input
                            type="text"
                            name="phoneNumber"
                            value={editSeller.phoneNumber}
                            onChange={handleChange}
                            placeholder="Phone Number"
                            required
                        />
                         <label htmlFor="emailID">Email ID</label>
                        <input
                            type="email"
                            name="emailID"
                            value={editSeller.emailID}
                            onChange={handleChange}
                            placeholder="Email ID"
                            required
                        />
                        <label htmlFor="website">Website</label>
                        <input
                            type="url"
                            name="website"
                            value={editSeller.website}
                            onChange={handleChange}
                            placeholder="Website"
                        />
                         <label htmlFor="city">City</label>
                        <input
                            type="text"
                            name="city"
                            value={editSeller.city}
                            onChange={handleChange}
                            placeholder="City"
                            required
                        />
                        <label htmlFor="state">State</label>
                        <input
                            type="text"
                            name="state"
                            value={editSeller.state}
                            onChange={handleChange}
                            placeholder="State"
                            required
                        />
                        </div>
                        <div className="form-group">
                            <label htmlFor="notes">Notes</label>
                            <input
                                type="text"
                                name="notes"
                                value={editSeller.notes}
                                onChange={handleChange}
                                placeholder="Notes"
                            />
                        </div>
                        <div className='submit-btn'>
                            <button type="submit">Update Seller</button>
                            <button type="button" onClick={() => setEditMode(false)}>Cancel</button>
                        </div>
                    </form>
                </div>
            ) : (
                <table>
                    <thead>
                        <tr>
                        <input
                                type="checkbox"
                                onChange={handleSelectAllChange}
                                checked={areAllSelected}
                            />
                            <th>Sl. No</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Company Name</th>
                            <th>Phone Number</th>
                            <th>Email ID</th>
                            <th>Website</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Notes</th>
                            <th>Last Mail Shared</th>
                            <th>Last WhatsApp Shared</th>
                            <th>Enquiry</th> 
                            <th>Edit</th>
                            <th>Email</th> 
                            <th>Delete</th> 
                            <th>Whatsapp</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {searchQuery? (
                            filteredSellers.length > 0 ?(
                                filteredSellers.map((seller, index) => (
                                <tr key={seller.id}>
                                    {/* Table content for filtered buyers */}
                            <td>
                                <input
                                    type="checkbox"
                                    checked={!!selectedSellers[seller.id]}
                                    onChange={() => handleCheckboxChange(seller.id)}
                                />
                            </td>
                                <td>{index + 1}</td> {/* This generates the serial number */}
                                <td>{seller.firstName}</td>
                                <td>{seller.lastName}</td>
                                <td>{seller.companyName}</td>
                                <td>{seller.phoneNumber}</td>
                                <td>{seller.emailID}</td>
                                <td>{seller.website}</td>
                                <td>{seller.city}</td>
                                <td>{seller.state}</td>
                                <td>{seller.notes}</td>
                                <td>{new Date(seller.lastMailShared).toLocaleDateString()}</td>
                                <td>{new Date(seller.lastWhatsAppShared).toLocaleDateString()}</td>
                                <td>
                                    <button onClick={() => handleEditClick(seller)}>Enquiry</button>
                                </td>
                                <td>
                                    <button onClick={() => handleEditClick(seller)}>Edit</button>
                                </td>
                                <td>
                                    <button onClick={() => sendEmail(seller)}>Email</button>
                                </td> {/* Send Email Button */}
                                <td>
                                    <button onClick={() => deleteSeller(seller.id)}>Delete</button> {/* Delete Button */}
                                </td> 
                                <td>
                                    <button onClick={() => toggleTemplates(seller.id)}>
                                        WhatsApp
                                    </button>
                                    {showTemplates[seller.id] && (
                                        <div className="template-options">
                                            <p>Select a template:</p>
                                            {templates.map((template, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() => handleTemplateSelect(seller, index)}
                                                >
                                                    {index === 0 ? "Seller" : "Other"}
                                                </button>
                                            ))}

                                            {/* If "Other" is selected, show the textarea */}
                                            {customMessageVisible[seller.id] && (
                                                <>
                                                    <textarea
                                                        placeholder="Enter your custom message"
                                                        value={customMessage[seller.id] || ""}
                                                        onChange={(e) => setCustomMessage((prev) => ({
                                                            ...prev,
                                                            [seller.id]: e.target.value // Update message for this seller
                                                        }))}
                                                    ></textarea>
                                                    <button onClick={() => sendWhatsapp(seller, customMessage[seller.id])}>
                                                        Send Custom Message
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))
                            ):(
                                <tr>
                                        <td colSpan="15" style={{ textAlign: "center" }}>
                                            No results found.
                                        </td>
                                    </tr>
                            )
                        ):(
                            sellers.map((seller, index) => (
                                <tr key={seller.id}>
                                    {/* Table content for all sellers */}
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={!!selectedSellers[seller.id]}
                                            onChange={() => handleCheckboxChange(seller.id)}
                                        />
                                    </td>
                                    <td>{index + 1}</td> {/* This generates the serial number */}
                                <td>{seller.firstName}</td>
                                <td>{seller.lastName}</td>
                                <td>{seller.companyName}</td>
                                <td>{seller.phoneNumber}</td>
                                <td>{seller.emailID}</td>
                                <td>{seller.website}</td>
                                <td>{seller.city}</td>
                                <td>{seller.state}</td>
                                    <td>{seller.lastMailShared ? new Date(seller.lastMailShared).toLocaleDateString() : "N/A"}</td>
                                    <td>{seller.lastWhatsAppShared ? new Date(seller.lastWhatsAppShared).toLocaleDateString() : "N/A"}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(seller)}>Enquiry</button>
                                    </td>
                                    <td>
                                        <button onClick={() => handleEditClick(seller)}>Edit</button>
                                    </td>
                                    <td>
                                        <button onClick={() => sendEmail(seller)}>Email</button>
                                    </td>
                                    <td>
                                        <button onClick={() => deleteSeller(seller.id)}>Delete</button>
                                    </td>
                                    <td>
                                        <button onClick={() => toggleTemplates(seller.id)}>
                                            WhatsApp
                                        </button>
                                        {showTemplates[seller.id] && (
                                            <div className="template-options">
                                                <p>Select a template:</p>
                                                {templates.map((template, index) => (
                                                    <button key={template + index} onClick={() => handleTemplateSelect(seller, index)}>
                                                        {index === 0 ? "Seller" : "Other"}
                                                    </button>
                                                ))}

                                                {customMessageVisible[seller.id] && (
                                                    <>
                                                        <textarea
                                                            placeholder="Enter your custom message"
                                                            value={customMessage[seller.id] || ""}
                                                            onChange={(e) =>
                                                                setCustomMessage((prev) => ({
                                                                    ...prev,
                                                                    [seller.id]: e.target.value,
                                                                }))
                                                            }
                                                        ></textarea>
                                                        <button onClick={() => sendWhatsapp(seller, customMessage[seller.id])}>
                                                            Send Custom Message
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default SellerList;
