import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './BuyerList.css'; // Import the stylesheet
import { buyerTemplate, otherTemplate } from '../../whatsapp_temp';

const BuyerList = () => {
    const [buyers, setBuyers] = useState([]);
    const [filteredBuyers, setFilteredBuyers] = useState([]); // New state for filtered buyers
    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [editMode, setEditMode] = useState(false);  // For toggling edit mode
    const [editBuyer, setEditBuyer] = useState({});  // Buyer being edited
    const [showTemplates, setShowTemplates] = useState({}); // To manage template visibility
    const [customMessage, setCustomMessage] = useState({}); // For handling custom message input
    const [customMessageVisible, setCustomMessageVisible] = useState({}); // To track visibility of the textarea
    const [loading, setLoading] = useState(true);
    const [selectedBuyers, setSelectedBuyers] = useState({}); // New state to track selected checkboxes

    // const [selectedTemplate, setSelectedTemplate] = useState(''); // To store the selected template

    // Fetch the Buyers from the backend
    useEffect(() => {
        const fetchBuyers = async () => {
            try {
                const response = await axios.get('http://localhost:5001/buyers');
                // Check for buyerID and sort buyers with valid IDs only
                const sortedBuyers = response.data.sort((a, b) => {
                    if (a.buyerID && b.buyerID) {
                        return a.buyerID.localeCompare(b.buyerID);
                    }
                    return 0; // No sorting if one of the buyerIDs is missing
                });
                setBuyers(sortedBuyers);
            } catch (error) {
                console.error('Error fetching buyers:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchBuyers();
    }, []);

    // Automatically filter buyers as searchQuery changes
    useEffect(() => {
        if (searchQuery.trim() === "") {
            setFilteredBuyers(buyers); // Reset to the full list if the query is empty
        } else {
            const filtered = buyers.filter((buyer) =>
                buyer.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                buyer.lastName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                buyer.phoneNumber?.includes(searchQuery) ||
                buyer.emailID?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                buyer.state?.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredBuyers(filtered);
        }
    }, [searchQuery, buyers]);

    if (loading) {
        return <div>Loading Buyer List...</div>;
    }

    // Handle "Select All" checkbox change
    const handleSelectAllChange = (e) => {
        const { checked } = e.target;
        const newSelectedBuyers = {};
        buyers.forEach((buyer) => {
            newSelectedBuyers[buyer.id] = checked;
        });
        setSelectedBuyers(newSelectedBuyers);
    };

    // Handle individual checkbox change
    const handleCheckboxChange = (buyerId) => {
        setSelectedBuyers((prev) => ({
            ...prev,
            [buyerId]: !prev[buyerId]
        }));
    };

    // Check if all buyers are selected
    const areAllSelected = buyers.length > 0 && buyers.every(buyer => selectedBuyers[buyer.id]);

    // Function to handle sending WhatsApp message
    const sendWhatsapp = (buyer, template) => {
        // Create the WhatsApp message by replacing placeholders with actual buyer details
        const whatsappMessage = template
            .replace("{firstName}", buyer.firstName)
            .replace("{lastName}", buyer.lastName)
            .replace("{companyName}", buyer.companyName);

        // WhatsApp URL encoding the message and phone number
        const whatsappUrl = `https://wa.me/${buyer.phoneNumber}?text=${encodeURIComponent(whatsappMessage)}`;

        // Open WhatsApp Web with the message
        window.open(whatsappUrl, '_blank');

        // Create updated buyer object with the current date for lastWhatsAppShared
        const updatedBuyer = {
            ...buyer,
            lastWhatsAppShared: new Date().toISOString() // Set current date as lastWhatsAppShared
        };

        // Update the buyer in the local state
        setBuyers(buyers.map(s => s.id === updatedBuyer.id ? updatedBuyer : s));

        // Optionally, you can make an API call to update this in the backend as well
        updateBuyer(updatedBuyer);
    };

    // Predefined templates
    const templates = [
        buyerTemplate, otherTemplate,
    ];

    // Function to handle template selection and sending the message
    const handleTemplateSelect = (buyer, templateIndex) => {
        if (templateIndex === 1) {
            // Show textarea for custom message if "Other" is selected
            setCustomMessageVisible((prev) => ({
                ...prev,
                [buyer.id]: true // Only show for the specific buyer
            }));
        } else {
            // Hide the textarea if any predefined template is selected
            setCustomMessageVisible((prev) => ({
                ...prev,
                [buyer.id]: false
            }));
            const template = templates[templateIndex];
            sendWhatsapp(buyer, template);
        }
    };    

    // Function to toggle template options
    const toggleTemplates = (buyerId) => {
        setShowTemplates((prev) => ({
            ...prev,
            [buyerId]: !prev[buyerId] // Toggle the visibility for the specific buyer
        }));
    };

    // Function to handle buyer update (PUT request)
    const updateBuyer = async (buyerData) => {
        try {
            const response = await axios.put(`http://localhost:5001/buyers/${buyerData.id}`, buyerData, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }
            });
            console.log('buyer updated:', response.data);
            // Update the seller in the local list
            setBuyers(buyers.map(s => s.id === response.data.id ? response.data : s));
            setEditMode(false); // Exit edit mode
        } catch (error) {
            console.error('Error updating buyer:', error);
        }
    };

    // Bulk Send WhatsApp to selected sellers
    const bulkSendWhatsapp = (templateIndex) => {
        const template = templates[templateIndex];
        buyers.forEach(buyer => {
            if (selectedBuyers[buyer.id]) {
                sendWhatsapp(buyer, template);
            }
        });
    };

    // Bulk Send Email to selected sellers
    const bulkSendEmail = async () => {
        const selected = buyers.filter(buyer => selectedBuyers[buyer.id]);
        for (let buyer of selected) {
            await sendEmail(buyer);
        }
    };

    // Updated function to handle sending email
    const sendEmail = async (buyer) => {
        try {
            const response = await axios.post(`http://localhost:5001/buyers/${buyer.id}/send-email`, {
                emailID: buyer.emailID,
                subject: "Vidyutcloud - E-commerce Website For Electrical Products",  // New subject
                buyerInfo: {  // Pass relevant buyer info to the backend
                    firstName: buyer.firstName,
                    lastName: buyer.lastName,
                    companyName: buyer.companyName,
                    phoneNumber: buyer.phoneNumber,
                    emailID: buyer.emailID,
                    city: buyer.city,
                    state: buyer.state
                }
            });
            console.log('Email sent:', response.data);
            alert(`Email sent to ${buyer.emailID}`);

            // Update the "lastMailShared" date after successful email
            const updatedBuyer = {
                ...buyer,
                lastMailShared: new Date().toISOString() // Set current date as lastMailShared
            };

            // Update the buyer in the local state
            setBuyers(buyers.map(s => s.id === updatedBuyer.id ? updatedBuyer : s));

            // Optionally, you can make an API call to update this in the backend as well
            await updateBuyer(updatedBuyer);
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error sending email');
        }
    };

    // Function to handle delete (DELETE request)
    const deleteBuyer = async (buyerId) => {
        if (window.confirm("Are you sure you want to delete this buyer?")) {
            try {
                const response = await axios.delete(`http://localhost:5001/buyers/${buyerId}`);
                console.log('buyer deleted:', response.data);
                // Remove the deleted buyer from the local list
                setBuyers(buyers.filter(buyer => buyer.id !== buyerId));
            } catch (error) {
                console.error('Error deleting buyer:', error);
                alert('Error deleting buyer');
            }
        }
    };

    // Function to handle edit click
    const handleEditClick = (buyer) => {
        setEditBuyer(buyer);
        setEditMode(true); // Enable edit mode
    };

    // Function to handle form input changes
    const handleChange = (e) => {
        setEditBuyer({
            ...editBuyer,
            [e.target.name]: e.target.value
        });
    };

    const states = [
        'Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chhattisgarh','Goa','Gujarat','Haryana','Himachal Pradesh','Jharkhand','Karnataka','Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland','Odisha','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal','Delhi','Chandigarh','Puducherry','Ladakh','Jammu and Kashmir','Lakshadweep'];

    // Check if any buyers are selected
    const anyBuyersSelected = Object.values(selectedBuyers).some(selected => selected);

    return (
        <div>
            <h2>Buyer List</h2>
                <div className="search-container">
                    {/* Search Input */}
                    <input
                        type="text"
                        placeholder="Search by First Name, Last Name, Contact Number, Email ID, or State"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"/>
                </div>

                {/* Bulk Actions */}
                {anyBuyersSelected && (
                    <div className="bulk-actions">
                        <button onClick={() => bulkSendEmail()}>Send Bulk Email</button>
                        <button onClick={() => bulkSendWhatsapp(0)}>Buyer</button>
                        <button onClick={() => bulkSendWhatsapp(1)}>Custom Message</button>
                    </div>
                )}
                {editMode ? (
                    <div className='editBuyer'>
                        <h3>Edit Buyer</h3>
                        <form className='editBuyerFrm' onSubmit={(e) => {
                            e.preventDefault();
                            updateBuyer(editBuyer); // Submit the updated Buyer
                        }}>
                            <div class="form-row">
                                <div className="form-group">
                                    <label htmlFor="firstName">First Name</label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={editBuyer.firstName}
                                        onChange={handleChange}
                                        placeholder="First Name"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input
                                    type="text"
                                    name="lastName"
                                    value={editBuyer.lastName}
                                    onChange={handleChange}
                                    placeholder="Last Name"
                                    required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="companyName">Company Name</label>
                                    <input
                                        type="text"
                                        name="companyName"
                                        value={editBuyer.companyName}
                                        onChange={handleChange}
                                        placeholder="Company Name"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <input
                                        type="text"
                                        name="phoneNumber"
                                        value={editBuyer.phoneNumber}
                                        onChange={handleChange}
                                        placeholder="Phone Number"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="emailID">Email ID</label>
                                    <input
                                        type="email"
                                        name="emailID"
                                        value={editBuyer.emailID}
                                        onChange={handleChange}
                                        placeholder="Email ID"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="city">City</label>
                                    <input
                                        type="text"
                                        name="city"
                                        value={editBuyer.city}
                                        onChange={handleChange}
                                        placeholder="City"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="state">State</label>
                                    <input
                                        type="text"
                                        name="state"
                                        value={editBuyer.state}
                                        onChange={handleChange}
                                        placeholder="State"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="notes">Notes</label>
                                    <input
                                        type="text"
                                        name="notes"
                                        value={editBuyer.notes}
                                        onChange={handleChange}
                                        placeholder="Notes"
                                    />
                                </div>
                            </div>
                            <div className='submit-btn'>
                                <button type="submit">Update Buyer</button>
                                <button type="button" onClick={() => setEditMode(false)}>Cancel</button>
                            </div>
                        </form>
                    </div>
                ) : (
                    <table>
                        <thead>
                            <tr>
                            <input
                                    type="checkbox"
                                    onChange={handleSelectAllChange}
                                    checked={areAllSelected}
                                />
                                <th>Sl. No</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Company Name</th>
                                <th>Phone Number</th>
                                <th>Email ID</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Notes</th>
                                <th>Last Mail</th>
                                <th>Last WhatsApp</th>
                                <th>Enquiry</th> 
                                <th>Edit</th>
                                <th>Email</th> 
                                <th>Delete</th> 
                                <th>Whatsapp</th> 
                            </tr>
                        </thead>
                        <tbody>
                            {searchQuery ? (
                                filteredBuyers.length > 0 ? (
                                    filteredBuyers.map((buyer, index) => (
                                        <tr key={buyer.id}>
                                            {/* Table content for filtered buyers */}
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={!!selectedBuyers[buyer.id]}
                                                    onChange={() => handleCheckboxChange(buyer.id)}
                                                />
                                            </td>
                                            <td>{index + 1}</td>
                                            <td>{buyer.firstName}</td>
                                            <td>{buyer.lastName}</td>
                                            <td>{buyer.companyName}</td>
                                            <td>{buyer.phoneNumber}</td>
                                            <td>{buyer.emailID}</td>
                                            <td>{buyer.city}</td>
                                            <td>{buyer.state}</td>
                                            <td>{buyer.notes}</td>
                                            <td>{buyer.lastMailShared ? new Date(buyer.lastMailShared).toLocaleDateString() : "N/A"}</td>
                                            <td>{buyer.lastWhatsAppShared ? new Date(buyer.lastWhatsAppShared).toLocaleDateString() : "N/A"}</td>
                                            <td>
                                                <button onClick={() => handleEditClick(buyer)}>Enquiry</button>
                                            </td>
                                            <td>
                                                <button onClick={() => handleEditClick(buyer)}>Edit</button>
                                            </td>
                                            <td>
                                                <button onClick={() => sendEmail(buyer)}>Email</button>
                                            </td>
                                            <td>
                                                <button onClick={() => deleteBuyer(buyer.id)}>Delete</button>
                                            </td>
                                            <td>
                                                <button onClick={() => toggleTemplates(buyer.id)}>
                                                    WhatsApp
                                                </button>
                                                {showTemplates[buyer.id] && (
                                                    <div className="template-options">
                                                        <p>Select a template:</p>
                                                        {templates.map((template, index) => (
                                                            <button key={template + index} onClick={() => handleTemplateSelect(buyer, index)}>
                                                                {index === 0 ? "Buyer" : "Other"}
                                                            </button>
                                                        ))}

                                                        {customMessageVisible[buyer.id] && (
                                                            <>
                                                                <textarea
                                                                    placeholder="Enter your custom message"
                                                                    value={customMessage[buyer.id] || ""}
                                                                    onChange={(e) =>
                                                                        setCustomMessage((prev) => ({
                                                                            ...prev,
                                                                            [buyer.id]: e.target.value,
                                                                        }))
                                                                    }
                                                                ></textarea>
                                                                <button onClick={() => sendWhatsapp(buyer, customMessage[buyer.id])}>
                                                                    Send Custom Message
                                                                </button>
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="15" style={{ textAlign: "center" }}>
                                            No results found.
                                        </td>
                                    </tr>
                                )
                            ) : (
                                buyers.map((buyer, index) => (
                                    <tr key={buyer.id}>
                                        {/* Table content for all buyers */}
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={!!selectedBuyers[buyer.id]}
                                                onChange={() => handleCheckboxChange(buyer.id)}
                                            />
                                        </td>
                                        <td>{index + 1}</td>
                                        <td>{buyer.firstName}</td>
                                        <td>{buyer.lastName}</td>
                                        <td>{buyer.companyName}</td>
                                        <td>{buyer.phoneNumber}</td>
                                        <td>{buyer.emailID}</td>
                                        <td>{buyer.city}</td>
                                        <td>{buyer.state}</td>
                                        <td>{buyer.notes}</td>
                                        <td>{buyer.lastMailShared ? new Date(buyer.lastMailShared).toLocaleDateString() : "N/A"}</td>
                                        <td>{buyer.lastWhatsAppShared ? new Date(buyer.lastWhatsAppShared).toLocaleDateString() : "N/A"}</td>
                                        <td>
                                            <button onClick={() => handleEditClick(buyer)}>Enquiry</button>
                                        </td>
                                        <td>
                                            <button onClick={() => handleEditClick(buyer)}>Edit</button>
                                        </td>
                                        <td>
                                            <button onClick={() => sendEmail(buyer)}>Email</button>
                                        </td>
                                        <td>
                                            <button onClick={() => deleteBuyer(buyer.id)}>Delete</button>
                                        </td>
                                        <td>
                                            <button onClick={() => toggleTemplates(buyer.id)}>
                                                WhatsApp
                                            </button>
                                            {showTemplates[buyer.id] && (
                                                <div className="template-options">
                                                    <p>Select a template:</p>
                                                    {templates.map((template, index) => (
                                                        <button key={template + index} onClick={() => handleTemplateSelect(buyer, index)}>
                                                            {index === 0 ? "Buyer" : "Other"}
                                                        </button>
                                                    ))}

                                                    {customMessageVisible[buyer.id] && (
                                                        <>
                                                            <textarea
                                                                placeholder="Enter your custom message"
                                                                value={customMessage[buyer.id] || ""}
                                                                onChange={(e) =>
                                                                    setCustomMessage((prev) => ({
                                                                        ...prev,
                                                                        [buyer.id]: e.target.value,
                                                                    }))
                                                                }
                                                            ></textarea>
                                                            <button onClick={() => sendWhatsapp(buyer, customMessage[buyer.id])}>
                                                                Send Custom Message
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                )}
        </div>
    );
};

export default BuyerList;
