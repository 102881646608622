// src/SellersPage.js
import React, {useState} from 'react';
import SellerForm from './SellerForm/SellerForm';
import SellerList from './SellerList/SellerList';

const ParentComponent = () => {
    const [sellers, setSellers] = useState([]);
    

    const handleAddSeller = (newSeller) => {
        setSellers((prevSellers) => [...prevSellers, newSeller]);
    };

    return (
        <div>
            <h1>Seller Management</h1>
            <SellerForm onAddSeller={handleAddSeller} />
            <SellerList/>
            {/* Render sellers list or other components here */}
        </div>
    );
};

export default ParentComponent;