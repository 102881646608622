// src/App.js
import React, { useState, createContext, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NavBar from './NavBar/NavBar';
import SellersPage from './Seller/SellersPage';
import EnquiryPage from './Enquiry/EnquiryPage';
import BuyerPage from './Buyer/BuyerPage';
import Home from './Home/home';
import LoginPage from './Login/LoginPage';
import './App.css';

// Export AuthContext so it can be used in other components
export const AuthContext = createContext();

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        return localStorage.getItem('isAuthenticated') === 'true';
    });

    useEffect(() => {
        localStorage.setItem('isAuthenticated', isAuthenticated);
    }, [isAuthenticated]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
            <Router>
                <div>
                    <NavBar />
                    <Routes>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/" element={<Home />} />
                        <Route path="/sellers" element={<ProtectedRoute><SellersPage /></ProtectedRoute>} />
                        <Route path="/enquiries" element={<ProtectedRoute><EnquiryPage /></ProtectedRoute>} />
                        <Route path="/buyers" element={<ProtectedRoute><BuyerPage /></ProtectedRoute>} />
                    </Routes>
                </div>
            </Router>
        </AuthContext.Provider>
    );
};

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useContext(AuthContext);
    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default App;
